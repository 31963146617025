<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Energetic Materials 101" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <img
          src="@/assets/Resources/cutpipe-min.jpg"
          width="300"
          height="225"
          alt=""
          title="High explosives residue in a cut 4-inch processing pipe"
          class="img-responsive wp-image-12260"
        />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Energetic materials are liquid or solid materials with a high amount
            of available stored chemical energy. Common energetic materials
            include propellants, explosives, pyrotechnic (PEP) compositions, and
            other fuel / oxidizer mixtures. Energetic materials are
            characterized by their sensitivity (the ability to initiate a
            reaction from an energy stimulus) and reactivity (propagation and
            effect characteristics after ignition).
          </p>
          <p>
            Ignition or initiation of an energetic material requires an energy
            stimulus. Forms of energy stimulus include impact, friction,
            electrostatic discharge (ESD) or electrical spark, shock and thermal
            / heating among others. Sources of energy stimulus include:
          </p>
          <ul>
            <li>Pinching, cutting, grinding, or slicing</li>
            <li>Sliding surfaces together</li>
            <li>Drilling, hammering, welding</li>
            <li>
              Static discharge, electrical spark, or mechanically produced spark
              (hand tools, power tools).
            </li>
          </ul>
          <p>High explosives residue in a cut 4-inch processing pipe</p>
          <p>
            Any tool, power device, or equipment has the potential to create an
            initiation event. “Non-sparking”, “spark-resistant” or “spark-proof”
            tools may not produce hot mechanical sparks, but may still initiate
            energetic materials by electrical spark, static discharge, being
            dropped (impact energy), or being dragged / rubbed (friction
            energy).
          </p>
          <p>
            Reactivity levels define the type of event that can be expected once
            a material has been ignited or initiated. Reactivity levels of
            concern include burning, deflagration, and explosion / detonation.
          </p>
          <p>
            Any lower level reaction (burn, deflagration) can escalate to a
            higher level (explosion / detonation) given the right conditions:
          </p>
          <ul>
            <li>
              Confinement: All energetic materials respond with greater violence
              when confined. For D3 operations, material ignited in a crack or
              joint may accelerate to an explosive event due to pressure
              buildup.
            </li>
            <li>
              Critical Height: Materials in sufficient quantity (depth), with
              minimal confinement, can progress to an explosion or even a
              detonation when bottom ignited by flame or heat.
            </li>
            <li>
              Dusting: Very finely divided materials can transition to explosive
              reaction events if fluffed during an ignition event. Fuels such as
              flour, sugar, and metal powders can become explosive under this
              condition.
            </li>
            <li>
              Fine Particles: Smaller particles of an energetic material may
              also have increased reactivity and sensitivity. For example,
              Ammonium Perchlorate (AP) with a particle size greater than 200µ
              has a hazards classification of 5.1 Oxidizer, whereas AP with a
              particle size smaller than 20µ is a Hazards Division (HD) 1.1
              explosive.
            </li>
            <li>
              Mixtures: Addition of contaminants or combinations of ingredients
              in vacuum systems, filter systems, and other processing areas can
              be more sensitive or reactive than ingredients alone.
            </li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Energetic Materials",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content: "The basics of energetic materials."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
